import React, { useState, useEffect }  from 'react';
import { connect } from "react-redux";
import * as html2canvas from 'html2canvas';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { collection, addDoc, serverTimestamp, getDocs } from "firebase/firestore";
import { getAuth, signInAnonymously } from "firebase/auth";
import * as turf from "@turf/turf";
import CustomPopup from '../components/PopUp';
import Loader from '../components/Loader';
import arrowRight from '../theme/img/angle-right.svg';
import trustPilot from '../theme/img/trustpilot.svg';

// Swatches
import midnight from '../theme/img/swatch/midnight.jpg';
import lightContours from '../theme/img/swatch/light-contours.jpg';
import darkContours from '../theme/img/swatch/dark-contours.jpg';
import blueprint from '../theme/img/swatch/blueprint.jpg';
import tangerine from '../theme/img/swatch/tangerine.jpg';
import satelite from '../theme/img/swatch/satelite.jpg';
import sepia from '../theme/img/swatch/sepia.jpg';
import oldWorld from '../theme/img/swatch/old-world.jpg';
import outline from '../theme/img/swatch/outline.jpg';

import arrowL from '../theme/img/l.svg';
import arrowR from '../theme/img/r.svg';
import arrowT from '../theme/img/t.svg';
import arrowB from '../theme/img/b.svg';
import arrowPlus from '../theme/img//plus-button.svg';
import arrowMinus from '../theme/img/mius-button.svg';


// Size Guide
import sizing from '../theme/img/frame-sizes.jpg';

//Actions
import bin from '../theme/img/bin.svg'

// Upload
import { storage } from "./../firebaseConfig.js"


import { setLocation, 
    setTitle, 
    setMessage, 
    setElevation, 
    setSmallText, 
    setStyle,
    setShowElevation,
    setShowStartStop,
    setCanvasSize,
    setZoom
} from "../actions";


const Design = (props) => {
  const [isSelected, setIsSelected] = useState(true);
  const [visibility, setVisibility] = useState(false);
  const [errorVisibility, setErrorVisibility] = useState(false);
  const [loaderVisibility, setLoaderVisibility] = useState(false);
  const [frame, setFrame] = useState('Unframed');
  const [frameColor, setFrameColour] = useState('N/A');
  const [canvas, setCanvas] = useState('8x12 (20x30cm)');
  const [price, setPrice] = useState('29.95');
  const [markersListWithText, setMarkersListWithText] = useState([]);
  const [zoomLevel, setZoomLevel] = useState(null);
  const auth = getAuth();
  const handleNextStep = (e) => {
    e.preventDefault();
    console.log('addToCart')
  }



  const getHeighGains = (unit) => {
    let output = [];
    const units = unit === 'feet' ? 'feet' : 'meters'

    props.geoJson.map(route => {
        const positions = route?.features[0].geometry.coordinates.map(p => [p[2]]);
        output.push(positions)
    });
    
    const merged = [].concat.apply([], output);

    var smaElevations = sma(merged, merged.length, 2);
    const limits = merged.length / 12;

    // const data = units === 'meters' ? smaElevations[0].toFixed(0) : (smaElevations[0] * 3.28084).toFixed(0);
    const data = units === 'meters' ? (limits).toFixed(0) : (limits * 3.28084).toFixed(0);

    return data
  }

  const getTotalDistance = (unit) => {
    let output = [];
    const units = unit === 'km' ? 'kilometers' : 'miles'
  
    if(props.geoJson) {
        props.geoJson.map(route => {
            const positions = route?.features[0].geometry.coordinates.map(p => [p[0], p[1]]);
            output.push(positions)
        });
        
        const merged = [].concat.apply([], output);

        var line = turf.lineString(merged);
        var length = turf.length(line, {units: units});

        return `${length.toFixed(0)}`
    }

  }

  const sma = (arr, range, precision) => {
    if (!Array.isArray(arr)) {
      throw TypeError('expected first argument to be an array');
    }
  
    precision = precision || 2;
    var num = range || arr.length;
    var res = [];
    var len = arr.length + 1;
    var idx = num - 1;
  
    while (++idx < len) {
      var value = avg(arr, idx, num);
      res.push(round(value, precision));
    }
  
    return res;
  };

  const avg = (arr, idx, range) => {
    return sum(arr.slice(idx - range, idx)) / range;
  }

  const round = (value, decimals) => {
    return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
  }

  const  sum = (arr) => {
    var len = arr.length;
    var num = 0;
  
    while (len--) {
      num += Number(arr[len]);
    }
  
    return num;
  }

  const isStrava = false;

  const toggleDraw = (e) => {
    e.preventDefault();
    const element = e.currentTarget;
    const parent = element.parentNode;
    const child = element.nextElementSibling;
    const parentAll = document.querySelectorAll('.accordion li');
    const childAll = document.querySelectorAll('.accordion .inner');

    // Remove all
    parentAll.forEach(box => {
        box.classList.remove('active');
    });

    childAll.forEach(box => {
        box.classList.remove('show');
    });


    // Add Current
    parent.classList.add('active');
    child.classList.add('show');

  }

  const handleZoomIn = () => {
    setZoomLevel(zoomLevel + 1);
    props.setZoom(zoomLevel + 1);
  };

  // Handler for zoom out button
  const handleZoomOut = () => {
    setZoomLevel(zoomLevel - 1);
    props.setZoom(zoomLevel - 1);
  };

  const handleTitleInput = (e) => {
    props.setTitle(e.target.value);
  }

  const handleLocationInput = (e) => {
    props.setLocation(e.target.value);
  }

  const handleMessageInput = (e) => {
    props.setMessage(e.target.value);
  }

  const handleElevationDataInput = (e) => {
    props.setElevation(e.target.value);
  }

  const handleMilesSelect = (e) => {
    const string = `${getTotalDistance('miles')} MILES // ${getHeighGains('feet')} FEET ELEVATION`;
    props.setElevation(string);
  }

  const handleKmSelect = (e) => {
    const string = `${getTotalDistance('km')} KM // ${getHeighGains('meters')} METRES ELEVATION`;
    props.setElevation(string)
  }

  const handleSmallInput = (e) => {
    props.setSmallText(e.target.value);
  }

  const handleStyleSelect = (e) => {
    e.preventDefault();
    const element = e.currentTarget;

    const elementSwatch = document.querySelectorAll('.swatch');
    elementSwatch.forEach(box => {
        box.classList.add('style-2');
        box.classList.remove('style-1');
    });

    element.querySelector('.swatch').classList.remove('style-2');
    element.querySelector('.swatch').classList.add('style-1');

    props.setStyle(element.querySelector('.name').innerText);
  }

  const handleLabelOneText = (e) => {
    document.querySelector('.user-marker-item-1').innerText = e.target.value
    updateArrayAtIndex(0,  e.target.value);
  }
  const handleLabelTwoText = (e) => {
    document.querySelector('.user-marker-item-2').innerText = e.target.value
    updateArrayAtIndex(1,  e.target.value);
  }
  const handleLabelThreeText = (e) => {
    document.querySelector('.user-marker-item-3').innerText = e.target.value
    updateArrayAtIndex(2,  e.target.value);
  }
  const handleLabelFourText = (e) => {
    document.querySelector('.user-marker-item-4').innerText = e.target.value
    updateArrayAtIndex(3,  e.target.value);
  }
  const handleLabelFiveText = (e) => {
    document.querySelector('.user-marker-item-5').innerText = e.target.value
    updateArrayAtIndex(4,  e.target.value);
  }
  const handleLabelSixText = (e) => {
    document.querySelector('.user-marker-item-6').innerText = e.target.value
    updateArrayAtIndex(5,  e.target.value);
  }
  const handleLabelSevenText = (e) => {
    document.querySelector('.user-marker-item-7').innerText = e.target.value
    updateArrayAtIndex(6,  e.target.value);
  }
  const handleLabelEightText = (e) => {
    document.querySelector('.user-marker-item-8').innerText = e.target.value
    updateArrayAtIndex(7,  e.target.value);
  }
  const handleLabelNineText = (e) => {
    document.querySelector('.user-marker-item-9').innerText = e.target.value
    updateArrayAtIndex(8,  e.target.value);
  }
  const handleLabelTenText = (e) => {
    document.querySelector('.user-marker-item-10').innerText = e.target.value
    updateArrayAtIndex(9,  e.target.value);
  }
  const handleLabelElevenText = (e) => {
    document.querySelector('.user-marker-item-11').innerText = e.target.value
    updateArrayAtIndex(10,  e.target.value);
  }
  const handleLabelTwelveText = (e) => {
    document.querySelector('.user-marker-item-12').innerText = e.target.value
    updateArrayAtIndex(11,  e.target.value);
  }
  const handleLabelThirteenText = (e) => {
    document.querySelector('.user-marker-item-13').innerText = e.target.value
    updateArrayAtIndex(12,  e.target.value);
  }
  const handleLabelFourteenText = (e) => {
    document.querySelector('.user-marker-item-14').innerText = e.target.value
    updateArrayAtIndex(13,  e.target.value);
  }
  const handleLabelFifteenText = (e) => {
    document.querySelector('.user-marker-item-15').innerText = e.target.value
    updateArrayAtIndex(14,  e.target.value);
  }
  const handleLabelSixteenText = (e) => {
    document.querySelector('.user-marker-item-16').innerText = e.target.value
    updateArrayAtIndex(15,  e.target.value);
  }
  const handleLabelSeventeenText = (e) => {
    document.querySelector('.user-marker-item-17').innerText = e.target.value
    updateArrayAtIndex(16,  e.target.value);
  }
  const handleLabelEighteenText = (e) => {
    document.querySelector('.user-marker-item-18').innerText = e.target.value
    updateArrayAtIndex(17,  e.target.value);
  }
  const handleLabelNineteenText = (e) => {
    document.querySelector('.user-marker-item-19').innerText = e.target.value
    updateArrayAtIndex(18,  e.target.value);
  }
  const handleLabelTwentyText = (e) => {
    document.querySelector('.user-marker-item-20').innerText = e.target.value
    updateArrayAtIndex(19,  e.target.value);
  }

  const handleShowElevation = (e) => {
    setIsSelected(!isSelected);
    props.setShowElevation(e.target.checked)
  }

  const handleShowEndpoints = (e) => {
    props.setShowStartStop(e.target.checked)
  }

  const canvasSize = (e) => {
    props.setCanvasSize(e.target.value);
    setCanvas(e.target.value);
    thePrice(frame, e.target.value)
  }

  const frameOption = (e) => {
    setFrame(e.target.value)
    thePrice(e.target.value, canvas);
  }

  const frameColour = (e) => {
    setFrameColour(e.target.value)
  }


  const thePrice = (frame, size) => {
     if(size === '8x12 (20x30cm)' && frame === 'Unframed') {
        setPrice('29.95');
    } else if(size === '12x16 (30x40cm)' && frame === 'Unframed') {
        setPrice('34.95');
    } else if(size === '16x20 (40x50cm)' && frame === 'Unframed') {
        setPrice('39.95');
    } else if(size === '8x12 (20x30cm)' && frame === 'Framed to edges') {
        setPrice('68.95');
    } else if(size === '12x16 (30x40cm)' && frame === 'Framed to edges') {
        setPrice('74.95');
    } else if(size === '16x20 (40x50cm)' && frame === 'Framed to edges') {
        setPrice('79.95');
    } else if(size === '8x12 (20x30cm)' && frame === 'Framed with white mount') {
        setPrice('74.95');
    } else if(size === '12x16 (30x40cm)' && frame === 'Framed with white mount') {
        setPrice('76.95');
    } else if(size === '16x20 (40x50cm)' && frame === 'Framed with white mount') {
        setPrice('x.xx');
    } 
  }

  const dataURItoBlob = (dataURI) => {
    var binary = atob(dataURI.split(',')[1]);
    var array = [];
    for(var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {type: 'image/jpeg'});
}

const handleRemoveLabel = (e) => {
    const element = e.currentTarget;
    const parent = element.parentNode;

    parent.classList.remove('visible');
    const id = parent.getAttribute('data-id');
    document.querySelector(`.user-marker-item-${id}`).classList.add('hidden')
}

const popupCloseHandler = (e) => {
    setVisibility(e);
};

const popupErrorCloseHandler = (e) => {
    setErrorVisibility(e);
};

// if(props.geoJson) {
//     handleMilesSelect()
// }





const exportPdf = (size) => {
    setLoaderVisibility(true);

    const outputSize = (size) => {
        let output = {}
        let localSize = size === null ? '8x12 (20x30cm)' : size

        if(localSize === '8x12 (20x30cm)' && frame === 'Unframed') {
            output = { width: 2400, height: 3600 }
        } else if(localSize === '8x12 (20x30cm)' && frame === 'Framed to edges') {
            output = { width: 2475, height: 3675 }
        } else if(localSize === '8x12 (20x30cm)' && frame === 'Framed with white mount') {
            output = { width: 2925, height: 4125 }
        } else if(localSize === '12x16 (30x40cm)' && frame === 'Unframed') {
            output = { width: 3600, height: 4800  }
        } else if(localSize === '12x16 (30x40cm)' && frame === 'Framed to edges') {
            output = { width: 3675, height: 4875  }
        } else if(localSize === '12x16 (30x40cm)' && frame === 'Framed with white mount') {
            output = { width: 3675, height: 4875  }
        } else if(localSize === '16x20 (40x50cm)' && frame === 'Unframed') {
            output = {  width: 4800, height: 6000 }
        } else if(localSize === '16x20 (40x50cm)' && frame === 'Framed to edges') {
            output = { width: 4875, height: 6075  }
        } else if(localSize === '16x20 (40x50cm)' && frame === 'Framed with white mount') {
            output = {  width: 4800, height: 6000 }
        }

        return output;
    }

    const fireEvent = (element,event) => {
        if (document.createEventObject){
        // dispatch for IE
        var evt = document.createEventObject();
        return element.fireEvent('on'+event,evt)
        }
        else{
        // dispatch for firefox + others
        var evt = document.createEvent("HTMLEvents");
        evt.initEvent(event, true, true ); // event type,bubbling,cancelable
        return !element.dispatchEvent(evt);
        }
    }

    const e1 = document.querySelectorAll('.mapboxgl-control-container');
    const e2 = document.querySelectorAll('.mapboxgl-ctrl-top-left');
    const e3 = document.querySelectorAll('.mapboxgl-ctrl-top-right');
    const e4 = document.querySelectorAll('.mapboxgl-ctrl-bottom-left');
    const e5 = document.querySelectorAll('.mapboxgl-canary');
    // const e6 = document.querySelectorAll('.mapboxgl-canvas-container');
    const branding = document.querySelectorAll('.rs-print');

    e1.forEach(box => {
        box.remove();
    });
    e2.forEach(box => {
        box.remove();
    });
    e3.forEach(box => {
        box.remove();
    });
    e4.forEach(box => {
        box.remove();
    });
    e5.forEach(box => {
        box.remove();
    });
    branding.forEach(box => {
        box.classList.add('exporting');
    });

    const resizeCanvas = (canvas, data) => {
        let newCanvas = document.createElement("canvas");
        newCanvas.height = data.height;
        newCanvas.width = data.width;
        let ctx2 = newCanvas.getContext('2d');

        ctx2.drawImage(canvas,0,0,canvas.width, canvas.height,0,0,data.width, data.height);

        return newCanvas;
    }



    const uploadOrderToFirestore = async (orderId, gpxArray) => {
        try {
            // Create the order object
            const orderData = {
                orderId: orderId, 
                customData: props.elevation,
                dataType: '',
                date: props.message,
                frameColor: frameColor,
                framing: frame,
                location: props.location,
                markers: markersListWithText,
                size: canvas,
                smallPrint: props.small,
                style: props.style,
                title: props.title,
                createdAt: serverTimestamp(), 
            };

            fetch('https://trailmaps.app.hoome.co.uk/api/firebase/database/createOrders', { 
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(orderData),
              })
            .then(response => response.json())
            .then(data => {
            })
            .then( async () => {
                const response = await fetch(`https://trailmaps.app.hoome.co.uk/api/mapbox/createImage?orderId=${orderId}`);
                    if (!response.ok) {
                    throw new Error('Failed to fetch order details');
                    }
            })
            .then( async () => {
                const previewResponse = await fetch(`https://trailmaps.app.hoome.co.uk/api/firebase/files/downloadUrls?filePath=ssr/${orderId}/preview_image.jpeg`);
                const data = await previewResponse.json();

                const previewsImage = data.url;

                signInAnonymously(auth)
                .then(() => {
        
                    html2canvas(document.querySelector(".rs-img"), {useCORS: true, scale: 10})
                    .then(tempCanvas => {
                        
                    const adjustedCanvas = resizeCanvas(tempCanvas, outputSize(size)) 
        
                    let dataUrl = adjustedCanvas.toDataURL('image/jpeg');
        
                    var contentWithoutMime = dataUrl.split(",")[1];
                    let sizeOfCanvasInBytes =  window.atob(contentWithoutMime).length;
        
                    if(sizeOfCanvasInBytes > 1) {
        
                        const storageRef = ref(storage, `/ssr/${props.orderId}/web_created.jpeg`);
        
                        const blobData = dataURItoBlob(dataUrl);
        
                        const uploadTask = uploadBytesResumable(storageRef, blobData);
        
                        let cSelect = document.querySelector(`#Option-template--15677032202420__main-0`);
                        document.querySelector(`#Option-template--15677032202420__main-0 option[value="${canvas}"]`).selected = true;
                        fireEvent(cSelect,'change');
                        
                        let fSelect =  document.querySelector(`#Option-template--15677032202420__main-1`);
                        document.querySelector(`#Option-template--15677032202420__main-1 option[value="${frame}"]`).selected = true;
                        fireEvent(fSelect,'change');
        
                        if(frame === 'N/A') {
                            let noFrame = document.querySelector(`#Option-template--15677032202420__main-2`);
                            document.querySelector(`#Option-template--15677032202420__main-2 option[value="N/A"]`).selected = true;
                            
                            fireEvent(noFrame,'change');
                        } else {
                            let withFrame = document.querySelector(`#Option-template--15677032202420__main-2`);
                            document.querySelector(`#Option-template--15677032202420__main-2 option[value="${frameColor}"]`).selected = true;
                            
                            fireEvent(withFrame,'change');
                        }
        
                        const branding = document.querySelectorAll('.rs-print');
                        branding.forEach(box => {
                            box.classList.remove('exporting');
                        });
                              
        
                        uploadTask.on(
                            "state_changed",
                            (snapshot) => {
                                const percent = Math.round(
                                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                                );
                            },
                            () => alert('There has been an error'),
                            () => {
                                // send to shopify
        
                                // document.querySelector('input[name="properties[DYO]"]').value = props.orderId;
                                // document.querySelector('.product-form__submit').click();
        
                                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                                    setLoaderVisibility(false);
                            
                                    // input
                                    document.querySelector('input[name="properties[Preview]"]').value = previewsImage;
                                    document.querySelector('input[name="properties[DYO]"]').value = props.orderId;
                                    document.querySelector('.product-form__submit').click();
                                });
                            }
                        );
                    } else {
                        setLoaderVisibility(false);
                        setErrorVisibility(true)
                    }
                })
                .catch(err => { console.log(err) });
        
            })
                
            }
                
            )
            .catch((error) => {
                console.error('Error:', error);
            });

        } catch (error) {
            console.error("Error uploading order to Firestore: ", error);
        }
    };


    uploadOrderToFirestore(props.orderId)





    .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    // ...
    });
    }

    const updateArrayAtIndex = (index, value) => {
        // Step 1: Copy the array
        const updatedArray = [...markersListWithText];
        
        // Step 2: Update the object at the specified index
        if (index >= 0 && index < updatedArray.length) {
          updatedArray[index] = { ...updatedArray[index], text: value };
        }
        
        // Step 3: Set the updated array (e.g., in state if using React)
        // setArray(updatedArray); // Uncomment this if you're using React and have state
        setMarkersListWithText(updatedArray);
      };

      useEffect(() => {
        const { markersJson } = props;
    
        // Ensure markersJson is an array
        const validMarkersJson = Array.isArray(markersJson) ? markersJson : [];
        
        // Ensure markersListWithText is an array
        const validMarkersListWithText = Array.isArray(markersListWithText) ? markersListWithText : [];
    
        // Create a map from existing markers with text
        const existingMarkersMap = new Map(validMarkersListWithText.map(item => [item.id, item]));
    
        // Update markersListWithText with new positions
        const updatedMarkersListWithText = validMarkersJson.map(marker => {
            const existingMarker = existingMarkersMap.get(marker.id);
            if (existingMarker) {
                return {
                    ...existingMarker,
                    lng: marker.lng,
                    lat: marker.lat,
                };
            } else {
                return {
                    lng: marker.lng,
                    lat: marker.lat,
                    text: '',
                };
            }
        });
    
        setMarkersListWithText(updatedMarkersListWithText);
    
    }, [props]);
    

    

    return (
      <>
        <Loader
            show={loaderVisibility}>
        </Loader>
        <CustomPopup
                        onClose={popupErrorCloseHandler}
                        show={errorVisibility}
                    >
                        <p>
                            Unfortunately there has been an error processing your design. Please email <a href="mailto:design@trailmaps.com">design@trailmaps.com</a> with your GPX file, style, and the details of your print so that we can assist further. A screenshot of your design would also help us to re-create it for you. <br />Thanks
                        </p>
                    </CustomPopup>
        <div className="step-title">
            <span>STEP 2</span>
            <div className="flex space-between ">
                <h2>Design</h2>
                <a href="https://trailmaps.com/pages/help-with-the-design-your-own-map-builder" target="_blank">Help</a>
            </div>
            </div>
            <div className="accordion-list">
            <ul className="accordion">
            <li className="active section-style">
                    <a className="toggle" onClick={toggleDraw}>Style</a>
                    <div className="inner show">
                        <div className="styles flex space-between">
                        <div className="single-style flex acenter"  onClick={handleStyleSelect}>
                            <div className="style-left">
                                <div className="swatch style-2">
                                    <img src={midnight} />
                                </div>
                            </div>
                            <div className="style-right">
                                <h3 className="name">Midnight</h3>
                            </div>
                        </div>
                        <div className="single-style flex acenter" onClick={handleStyleSelect}>
                            <div className="style-left">
                                <div className="swatch style-2">
                                    <img src={darkContours} />
                                </div>
                            </div>
                            <div className="style-right">
                                <h3 className="name">Dark Contours</h3>
                            </div>
                        </div>
                        <div className="single-style flex acenter" onClick={handleStyleSelect}>
                            <div className="style-left">
                                <div className="swatch style-2">
                                    <img src={lightContours} />
                                </div>
                            </div>
                            <div className="style-right">
                                <h3 className="name">Light Contours</h3>
                            </div>
                        </div>
                        <div className="single-style flex acenter" onClick={handleStyleSelect}>
                            <div className="style-left">
                                <div className="swatch style-2">
                                    <img src={blueprint} />
                                </div>
                            </div>
                            <div className="style-right">
                                <h3 className="name">Blueprint</h3>
                            </div>
                        </div>
                        <div className="single-style flex acenter" onClick={handleStyleSelect}>
                            <div className="style-left">
                                <div className="swatch style-2">
                                    <img src={tangerine} />
                                </div>
                            </div>
                            <div className="style-right">
                                <h3 className="name">Tangerine</h3>
                            </div>
                        </div>
                        <div className="single-style flex acenter" onClick={handleStyleSelect}>
                            <div className="style-left">
                                <div className="swatch style-2">
                                    <img src={satelite} />
                                </div>
                            </div>
                            <div className="style-right">
                                <h3 className="name">Satelite</h3>
                            </div>
                        </div>
                        <div className="single-style flex acenter" onClick={handleStyleSelect}>
                            <div className="style-left">
                                <div className="swatch style-2">
                                    <img src={sepia} />
                                </div>
                            </div>
                            <div className="style-right">
                                <h3 className="name">Sepia</h3>
                            </div>
                        </div>
                        <div className="single-style flex acenter" onClick={handleStyleSelect}>
                            <div className="style-left">
                                <div className="swatch style-2">
                                    <img src={oldWorld} />
                                </div>
                            </div>
                            <div className="style-right">
                                <h3 className="name">Old World</h3>
                            </div>
                        </div>
                        <div className="single-style flex acenter" onClick={handleStyleSelect}>
                            <div className="style-left">
                                <div className="swatch style-2">
                                    <img src={outline} />
                                </div>
                            </div>
                            <div className="style-right">
                                <h3 className="name">Outline</h3>
                            </div>
                        </div>
                        </div>
                    </div>
                </li>
                <li className="section-your-text">
                    <a className="toggle" onClick={toggleDraw}>
                    Your Text
                    </a>
                    <div className="inner">
                        <div className="labels">
                        <div className="label-single">
                            <input type="text" placeholder="Title" onChange={(e => handleTitleInput(e))} maxLength="38"/>
                        </div>
                        <div className="label-single">
                            <input type="text" placeholder="Location" onChange={(e => handleLocationInput(e))}/>
                        </div>
                        <div className="label-single">
                            <input type="text" placeholder="Date (left hand corner)" onChange={(e => handleMessageInput(e))}/>
                        </div>
                        <div className="label-single">
                            <input type="text" placeholder="Small print (right hand corner" onChange={(e => handleSmallInput(e))}/>
                        </div>
                        </div>
                    </div>
                </li>
                <li className="section-position-layout">
                    <a className="toggle" onClick={toggleDraw}>Position and Layout</a>
                    <div className="inner">
                        <div className="pl-text">
                        <p>Click and drag on the map to move it around into your desired position. Scroll in and out to adjust the zoom level.</p>
                        </div>
                        <div className="pl-control flex space-between wrap">
                        {/* <div className="pl-left">
                            <button className="l">
                            <img src={arrowL} alt="control" />
                            </button>
                            <button className="r">
                            <img src={arrowR} alt="control"/>
                            </button>
                            <button className="t">
                            <img src={arrowT} alt="control"/>
                            </button>
                            <button className="b">
                            <img src={arrowB} alt="control"/>
                            </button>
                        </div>  */}
                        {/* <div className="pl-right">
                            <div className="zoom-in-out">
                                <div className="zoom-in">
                                    <button onClick={handleZoomIn}>
                                    <img src={arrowPlus} alt="zoom in"/>
                                    </button>
                                </div>
                                <div className="zoom-out">
                                    <button onClick={handleZoomOut}>
                                    <img src={arrowMinus} alt="zoom out"/>
                                    </button>
                                </div>
                            </div>
                        </div> */}
                        </div>
                    </div>
                </li>
                <li className="section-data">
                    <a className="toggle" onClick={toggleDraw}>Data</a>
                    <div className="inner">
                        <div className="data">
                        <div className="custom-radio"> 

                            <div className="single-radio">
                                <div className="radio">
                                    <input id="radio-1" name="radio" type="radio" />
                                    <label htmlFor="radio-1" className="radio-label" onClick={(e => handleMilesSelect(e))}>Miles and Feet</label>
                                </div>
                            </div>
                            <div className="single-radio">
                                <div className="radio">
                                    <input id="radio-2" name="radio" type="radio" />
                                    <label htmlFor="radio-2" className="radio-label" onClick={(e => handleKmSelect(e))}>KM and Meters</label>
                                </div>
                            </div>
                            
                            <div className="single-radio">
                                <div className="radio">
                                    <input id="radio-3" name="radio" type="radio" />
                                    <label htmlFor="radio-3" className="radio-label">Custom (edit data)</label>
                                </div>
                            </div>
                        </div>
                        <div className="data-input">
                            <input type="text" placeholder="434 MILES  //  6,000 FEET" onChange={(e => handleElevationDataInput(e))} />
                        </div>

                        <div className="my-radio flex space-between">
                            <span>Show elevation profile</span>
                            <span className="radio-style">
                            <label className="radio-switch">
                            <input type="checkbox" checked={isSelected} onChange={(e => handleShowElevation(e))} />
                            <span className="radio-slider round"></span>
                            </label>
                            </span>
                        </div>
                        {/* <div className="my-radio flex space-between">
                            <span>Show start/stop points</span>
                            <span className="radio-style">
                            <label className="radio-switch">
                            <input type="checkbox" onChange={(e => handleShowEndpoints(e))} />
                            <span className="radio-slider round"></span>
                            </label>
                            </span>
                        </div> */}
                        </div>
                    </div>
                </li>
                <li className="section-labels">
                    <a className="toggle" onClick={toggleDraw}>
                    Add Labels
                    </a>
                    <div className="inner">
                        <div className="pl-text">
                        <p>Click anywhere on the map to add a label (click and drag to move them around)</p>
                        </div>
                        <div className="labels">
                        <div className="label-single label-marker-input label-marker-input-1">
                            <input type="text" placeholder="Name of a trail or cafe stop" onChange={(e => handleLabelOneText(e))}/>
                            <img src={bin} className="" onClick={(e => handleRemoveLabel(e))} />
                        </div>
                        <div className="label-single label-marker-input label-marker-input-2">
                            <input type="text" placeholder="Name of a trail or cafe stop" onChange={(e => handleLabelTwoText(e))}/>
                            <img src={bin} className="" onClick={(e => handleRemoveLabel(e))} />
                        </div>
                        <div className="label-single label-marker-input label-marker-input-3">
                            <input type="text" placeholder="Name of a trail or cafe stop" onChange={(e => handleLabelThreeText(e))}/>
                            <img src={bin} className="" onClick={(e => handleRemoveLabel(e))} />
                        </div>
                        <div className="label-single label-marker-input label-marker-input-4">
                            <input type="text" placeholder="Name of a trail or cafe stop" onChange={(e => handleLabelFourText(e))}/>
                            <img src={bin} className="" onClick={(e => handleRemoveLabel(e))} />
                        </div>
                        <div className="label-single label-marker-input label-marker-input-5">
                            <input type="text" placeholder="Name of a trail or cafe stop" onChange={(e => handleLabelFiveText(e))}/>
                            <img src={bin} className="" onClick={(e => handleRemoveLabel(e))} />
                        </div>
                        <div className="label-single label-marker-input label-marker-input-6">
                            <input type="text" placeholder="Name of a trail or cafe stop" onChange={(e => handleLabelSixText(e))}/>
                            <img src={bin} className="" onClick={(e => handleRemoveLabel(e))} />
                        </div>
                        <div className="label-single label-marker-input label-marker-input-7">
                            <input type="text" placeholder="Name of a trail or cafe stop" onChange={(e => handleLabelSevenText(e))}/>
                            <img src={bin} className="" onClick={(e => handleRemoveLabel(e))} />
                        </div>
                        <div className="label-single label-marker-input label-marker-input-8">
                            <input type="text" placeholder="Name of a trail or cafe stop" onChange={(e => handleLabelEightText(e))}/>
                            <img src={bin} className="" onClick={(e => handleRemoveLabel(e))} />
                        </div>
                        <div className="label-single label-marker-input label-marker-input-9">
                            <input type="text" placeholder="Name of a trail or cafe stop" onChange={(e => handleLabelNineText(e))}/>
                            <img src={bin} className="" onClick={(e => handleRemoveLabel(e))} />
                        </div>
                        <div className="label-single label-marker-input label-marker-input-10">
                            <input type="text" placeholder="Name of a trail or cafe stop" onChange={(e => handleLabelTenText(e))}/>
                            <img src={bin} className="" onClick={(e => handleRemoveLabel(e))} />
                        </div>
                        <div className="label-single label-marker-input label-marker-input-11">
                            <input type="text" placeholder="Name of a trail or cafe stop" onChange={(e => handleLabelElevenText(e))}/>
                            <img src={bin} className="" onClick={(e => handleRemoveLabel(e))} />
                        </div>
                        <div className="label-single label-marker-input label-marker-input-12">
                            <input type="text" placeholder="Name of a trail or cafe stop" onChange={(e => handleLabelTwelveText(e))}/>
                            <img src={bin} className="" onClick={(e => handleRemoveLabel(e))} />
                        </div>
                        <div className="label-single label-marker-input label-marker-input-13">
                            <input type="text" placeholder="Name of a trail or cafe stop" onChange={(e => handleLabelThirteenText(e))}/>
                            <img src={bin} className="" onClick={(e => handleRemoveLabel(e))} />
                        </div>
                        <div className="label-single label-marker-input label-marker-input-14">
                            <input type="text" placeholder="Name of a trail or cafe stop" onChange={(e => handleLabelFourteenText(e))}/>
                            <img src={bin} className="" onClick={(e => handleRemoveLabel(e))} />
                        </div>
                        <div className="label-single label-marker-input label-marker-input-15">
                            <input type="text" placeholder="Name of a trail or cafe stop" onChange={(e => handleLabelFifteenText(e))}/>
                            <img src={bin} className="" onClick={(e => handleRemoveLabel(e))} />
                        </div>
                        <div className="label-single label-marker-input label-marker-input-16">
                            <input type="text" placeholder="Name of a trail or cafe stop" onChange={(e => handleLabelSixteenText(e))}/>
                            <img src={bin} className="" onClick={(e => handleRemoveLabel(e))} />
                        </div>
                        <div className="label-single label-marker-input label-marker-input-17">
                            <input type="text" placeholder="Name of a trail or cafe stop" onChange={(e => handleLabelSeventeenText(e))}/>
                            <img src={bin} className="" onClick={(e => handleRemoveLabel(e))} />
                        </div>
                        <div className="label-single label-marker-input label-marker-input-18">
                            <input type="text" placeholder="Name of a trail or cafe stop" onChange={(e => handleLabelEighteenText(e))}/>
                            <img src={bin} className="" onClick={(e => handleRemoveLabel(e))} />
                        </div>
                        <div className="label-single label-marker-input label-marker-input-19">
                            <input type="text" placeholder="Name of a trail or cafe stop" onChange={(e => handleLabelNineteenText(e))}/>
                            <img src={bin} className="" onClick={(e => handleRemoveLabel(e))} />
                        </div>
                        <div className="label-single label-marker-input label-marker-input-20">
                            <input type="text" placeholder="Name of a trail or cafe stop" onChange={(e => handleLabelTwentyText(e))}/>
                            <img src={bin} className="" onClick={(e => handleRemoveLabel(e))} />
                        </div>
                        </div>
                    </div>
                </li>
                <li>
                    <a className="toggle" onClick={toggleDraw}>Size &amp; Framing</a>
                    <div className="inner">
                    <button className="size-examples" onClick={(e) => setVisibility(!visibility)}>Not sure which size to get? <span>View size examples</span></button>

                    <CustomPopup
                        onClose={popupCloseHandler}
                        show={visibility}
                    >
                        <img src={sizing} alt="sizing-guide" />
                    </CustomPopup>
                     
                        <div className="product-size-selector">
                            <label>Size</label>
                            <div onChange={(e) => canvasSize(e)}>
                            <select className="select__select" name="options[Size]" form="product-form-template--15677032202420__main"><option value="8x12 (20x30cm)" >
                              8x12" (20x30cm)
                            </option><option value="12x16 (30x40cm)">
                              12x16" (30x40cm)
                            </option><option value="16x20 (40x50cm)">
                              16x20" (40x50cm)
                            </option></select>
                            </div>
                        </div>
                        <div className="product-frame-selector">
                            <label>Framing</label>
                            <div>
                            <select onChange={(e) => frameOption(e)} className="select__select" name="options[Frame]" form="product-form-template--15677032202420__main"><option value="Unframed">
                              Unframed
                            </option><option value="Framed to edges">
                              Framed to edges
                            </option><option value="Framed with white mount">
                              Framed with white mount (8x12 or 12x16 only)
                            </option></select>
                            </div>
                        </div>
                        <div className="product-colour-selector">
                            <label>Frame Colour</label>
                            <div>
                            <select onChange={(e) => frameColour(e)} className="select__select" name="options[Frame Colour]" form="product-form-template--15677032202420__main">
                            <option value="N/A">
                              N/A
                            </option><option value="Black">
                              Black
                            </option><option value="White">
                              White
                            </option><option value="Natural Wood">
                              Natural Wood
                            </option><option value="Antique Gold">
                              Antique Gold
                            </option><option value="Antique Silver">
                              Antique Silver
                            </option></select>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
            </div>
            <div className="bottom-links">
            <div className="next-step">
                <div className={`connect flex space-between acenter ${canvas === '16x20 (40x50cm)' && frame === 'Framed with white mount' ? 'disabled' : ''}`} onClick={() => exportPdf(props.currentCanvasSize)}>
                    <div className="c-left">
                        <a href="#">
                        £{price}
                        </a>
                    </div>
                    <div className="c-right">
                        <a className="flex acenter">
                        <span>Add to Cart</span>
                        <img src={arrowRight} alt="right"/></a>
                    </div>
                </div>
            </div>
            <div className="trust-pilot flex space-between acenter">
                <div className="tp-left">
                    <span>
                        <strong>Excellent</strong> | Trustscore 4.9 | <a href="#">152 reviews</a>
                    </span>
                </div>
                <div className="tp-left right">
                    <img src={trustPilot} alt="trust-pilot"/>
                </div>
            </div>
        </div>
      </>
    );
  }
  
  const mapStateToProps = (state) => {
    
    return { 
        message: state.message,
        title: state.title,
        location: state.location,
        elevation: state.elevation,
        currentCanvasSize: state.canvasSize,
        geoJson: state.geoJson,
        markersJson: state.markersJson,
        orderId: state.orderId,
        small: state.small,
        style: state.style,
        canvasSize: state.canvasSize,
        zoom: state.zoom
    };
  };

  export default connect(mapStateToProps, {
    setLocation,
    setMessage,
    setTitle,
    setElevation,
    setSmallText,
    setStyle,
    setShowElevation,
    setShowStartStop,
    setCanvasSize,
    setZoom,
  })(Design);

  