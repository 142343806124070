import React, { useState, useRef, useEffect } from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import { connect, useDispatch } from "react-redux";
import Chart from 'chart.js/auto';
import * as turf from "@turf/turf";
import { Line } from "react-chartjs-2";
import { useLocation } from 'react-router-dom'

import { 
  setMarkersJson
} from "../../actions";

import logoBlack from '../../theme/img/logo-black-01.png';
import logoWhite from '../../theme/img/white-logo-01.png';

mapboxgl.accessToken = 'pk.eyJ1IjoidHJhaWxtYXBzdWsiLCJhIjoiY2sybjI3M2FpMDlkbzNtbnZrNzF6emtuZyJ9.DuL81krriZaK7daNSKYVhw';

const Map = ({title, location, message, elevation, small, geoJson, style, showElevation, showStartStop, canvasSize, stravaJson, zoom}) => {
    const dispatch = useDispatch();
    const mapContainer = useRef(null);
    const map = useRef(null);
    const routeLocation = useLocation();
    const [lng, setLng] = useState(6.7089);
    const [lat, setLat] = useState(46.1792);
    // const [zoom, setZoom] = useState(9); 
    const [route, setRoute] = useState('XXX KM   //   X,XXX METRES ELEVATION   //   XX:XX:XX MOVING TIME');
    const [printTitle, setTitle] = useState('Title of your route');
    const [subTitle, setSubTitle] = useState('Location or sub-title');
    const [printMessage, setMessage] = useState('Small message');
    const [messageRight, setMessageRight] = useState('xxx message'); 
    const [points, setPoints] = useState([]);
    const [count, setCount] = useState(0);
    const [selectedStyle, setSelectedStyle] = useState([{
      'name': 'Midnight',
      'path': 'mapbox://styles/trailmapsuk/ck2n3g96v01uw1cobmghtifba',
      'outline': '#FFFFFF',
      'gpx': '#FFFFFF'
    }]);
    const [markersList, setMarkersList] = useState([]);
    
    // GPX Profile Data
    const data = {
      labels: points,
      datasets: [
        {
          label: "dataset",
          data: points,
          fill: true,
          backgroundColor: selectedStyle[0].gpx,
          borderColor: selectedStyle[0].gpx
        }
      ]
    };
    
    const formatString = (value) => {
      if (!value) return;
      return value.replace(' ', '-').toLowerCase();
    }
    
    const styles = [
      {
        'name': 'Midnight',
        'path': 'mapbox://styles/trailmapsuk/ck2n3g96v01uw1cobmghtifba',
        'outline': '#FFFFFF',
        'gpx': '#FFFFFF'
      },
      {
        'name': 'Dark Contours',
        'path': 'mapbox://styles/trailmapsuk/ckjyck9r51u4k17pc9gifspyr',
        'outline': '#FFFFFF',
        'gpx': '#FFFFFF'
      },
      {
        'name': 'Light Contours',
        'path': 'mapbox://styles/trailmapsuk/cl6yrb4za00bj15qofasrts1v',
        'outline': '#000000',
        'gpx': '#000000'
      },
      {
        'name': 'Blueprint',
        'path': 'mapbox://styles/trailmapsuk/cl6orn7yd000214nvodw9als0',
        'outline': '#FFFFFF',
        'gpx': '#FFFFFF'
      },
      {
        'name': 'Tangerine',
        'path': 'mapbox://styles/trailmapsuk/cl7rd3s8x003n14kb2ewxd90v',
        'outline': '#FFFFFF',
        'gpx': '#FFFFFF'
      },
      {
        'name': 'Satelite',
        'path': 'mapbox://styles/trailmapsuk/cl6nlme8b000014pqdpeihyzz',
        'outline': '#FFFFFF',
        'gpx': '#FFFFFF'
      },
      {
        'name': 'Sepia',
        'path': 'mapbox://styles/trailmapsuk/cl6c46s6t000a15nsna8jrhd7',
        'outline': '#CB1517',
        'gpx': '#000000'
      },
      {
        'name': 'Old World',
        'path': 'mapbox://styles/trailmapsuk/cl7rhs3y9005c15rys6eipngc',
        'outline': '#211C17',
        'gpx': '#211C17'
      },
      {
        'name': 'Outline',
        'path': 'mapbox://styles/trailmapsuk/ckkql4abf1jln17mmkku8sxdl',
        'outline': '#FFFFFF',
        'gpx': '#FFFFFF'
      }
    ]

    const logoStyle = (style) => {
      switch(style) {
        case 'Midnight':
          return logoWhite;
        case 'Dark Contours':
          return logoWhite;
        case 'Light Contours':
          return logoBlack;
        case 'Blueprint':
          return logoWhite;
        case 'Tangerine':
          return logoWhite;
        case 'Satelite':
          return logoWhite;
        case 'Sepia':
            return logoBlack;
        case 'Old World':
          return logoWhite;
        case 'Outline':
          return logoWhite; 
        default:
          return logoWhite;
      }
    }

    

    const options = {
      plugins: {
        legend: {
            display: false
        },
      },
      scales: {
        x: {
          min: 0,
          display: false,
          ticks: {
            display: false
          },
          grid: {
            display: false
          }
       },
       y: {
        min: 0,
        display: false,
        ticks: {
          display: false
        },
        grid: {
          display: false
      }
    }
    },
    elements: {
      point:{
          radius: 0
      }
    },
    responsive: true,
    maintainAspectRatio: false,
  }


  const multiMarkers = (geo, type) => {
    let output = [];
  

    if(type !== 'strava') {
      geo.map(route => {
        const positions = route?.features[0].geometry.coordinates.map(p => [p[0], p[1]]);
        output.push(
          {
            'type': 'Feature',
            'properties': {},
            'geometry': {
              'type': 'LineString',
              'coordinates': positions
            }
          }
        )
      });
    } else {
      output.push(
        {
          'type': 'Feature',
          'properties': {},
          'geometry': {
            'type': 'LineString',
            'coordinates': geo.coords
          }
        }
      )
    }

    return output;
  }

  const allCords = (geo) => {
    let output = [];
  
    geo.map(route => {
      const positions = route?.features[0].geometry.coordinates.map(p => [p[0], p[1]]);
      output.push(positions)
    });
    

    const merged = [].concat.apply([], output);

    return merged;
  }

  const multiAltitude = (geo) => {
    let output = [];
    geo.map(route => {
      const positions = route?.features[0].geometry.coordinates.map(p => p[2]); 
      
      output.push(positions)
    });

    const merged = [].concat.apply([], output);

    return merged
  }

  const addMarker = (e) => {
    const count = document.querySelectorAll('.user-marker').length;
    const adjustedCount = count + 1;
    const parentAll = document.querySelectorAll('.accordion li');
    const childAll = document.querySelectorAll('.accordion .inner');
    const coordinates = e.lngLat;
    const el = document.createElement('div');
    el.className = `marker no-icon user-marker user-marker-item-${adjustedCount}`;
    el.setAttribute('marker', 'user-marker');
    el.textContent = 'Add your text';
    const markerLabel = document.querySelector(`.label-marker-input-${adjustedCount}`);
    markerLabel.classList.add('visible');
    markerLabel.setAttribute('data-id', adjustedCount);
    setTimeout(() => {
      document.querySelector(`.label-marker-input-${adjustedCount} input`).focus();
    }, 500)

   

    // Remove all
    parentAll.forEach(box => {
        box.classList.remove('active');
    });

    childAll.forEach(box => {
        box.classList.remove('show');
    });
    
    document.querySelector('.section-labels').classList.add('active');
    document.querySelector('.section-labels .inner').classList.add('show');

    const marker = new mapboxgl.Marker(el, {
      draggable: true
    });
    
    marker.setLngLat(coordinates).addTo(map.current);

    setMarkersList((prevItems) => {
      const newMarkersList = [...prevItems, coordinates];
      dispatch(setMarkersJson(newMarkersList)); 
      return newMarkersList;
    });

    // Update coordinates in markers list when marker is dragged
    marker.on('dragend', () => {
      const newCoords = marker.getLngLat();
      setMarkersList((prevItems) => {
        const updatedMarkersList = prevItems.map((item, index) => (index === adjustedCount - 1 ? newCoords : item));
        dispatch(setMarkersJson(updatedMarkersList)); // Dispatch the action to update Redux store
        return updatedMarkersList;
      });
    });
  }



  setMarkersJson(markersList)

  const startEndMarker = (e, postion) => {
    const el = document.createElement('div');
    el.className = `marker start-end-marker position-${postion}`;
    const marker = new mapboxgl.Marker(el);

    const coordinates = {
      lng: postion === 'start' ? e.geometry.coordinates[0][0] : e.geometry.coordinates[e.geometry.coordinates.length - 1][0],
      lat: postion === 'start' ? e.geometry.coordinates[0][1] : e.geometry.coordinates[e.geometry.coordinates.length - 1][1],
    }


    marker.setLngLat(coordinates).addTo(map.current);
  }

  useEffect(() => {
    if (map.current) return;
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: selectedStyle[0].path,
      attributionControl: false,
      logo: false,
      preserveDrawingBuffer: true,
      // dragPan: false,
      // scrollZoom: false
    });
  })


  useEffect(() => {
    if(map.current && geoJson) {
      let selectedStyle = style ? styles.filter(item => item.name === style) : [{
        'name': 'Midnight',
        'path': 'mapbox://styles/trailmapsuk/ck2n3g96v01uw1cobmghtifba',
        'outline': '#FFFFFF',
        'gpx': '#FFFFFF'
      }];


      setCount(count + 1);

      map.current.setStyle(selectedStyle[0].path);
      setSelectedStyle(selectedStyle);


      map.current.on('sourcedata', (e) => {
      if (map.current.getLayer('multiple-lines-layer')) {
        map.current.setPaintProperty('multiple-lines-layer', 'line-color', selectedStyle[0].outline);
      } else {
        map.current.removeLayer('multiple-lines-layer');
        if (map.current.getSource('multiple-lines-source')) {
          map.current.removeSource('multiple-lines-source');
        }
        map.current.addSource('multiple-lines-source', {
          'type': 'geojson',
          'data': {
            'type': 'FeatureCollection',
            'features': multiMarkers(geoJson)
          }, 
        });

        map.current.addLayer({
          'id': 'multiple-lines-layer',
          'type': 'line',
          'source': 'multiple-lines-source',
          'layout': {
          },
          'paint': {
            'line-color': selectedStyle[0].outline,
            'line-width': 5
          },
        });
      }
      });
  }
  }, [style])

  useEffect(() => {
    map.current.resize();
  }, [canvasSize])

  useEffect(() => {
    // if (!map.current || zoom === 0) return; // Check if map is initialized and zoom is not zero

    // // Get the current zoom level and adjust based on the zoom value
    // const currentZoom = map.current.getZoom();
    // const newZoom = currentZoom + zoom;

    // // Center the map around its current center and set the new zoom level
    // map.current.flyTo({
    //     center: map.current.getCenter(),
    //     zoom: newZoom
    // });
}, [zoom]);

  useEffect(() => {
      // if (!map.current) return; 
      let selectedStyle = style ? styles.filter(item => item.name === style) : [{
        'name': 'Midnight',
        'path': 'mapbox://styles/trailmapsuk/ck2n3g96v01uw1cobmghtifba',
        'outline': '#FFFFFF',
        'gpx': '#FFFFFF'
      }];

      
  
      if(geoJson) {
        map.current.on('click', (e) => {
          addMarker(e)
        });

        setPoints(multiAltitude(geoJson));

        if (map.current.getLayer('multiple-lines-layer')) {
          map.current.removeLayer('multiple-lines-layer');
        }
        if (map.current.getSource('multiple-lines-source')) {
          map.current.removeSource('multiple-lines-source');
        }

        map.current.addSource('multiple-lines-source', {
          'type': 'geojson',
          'data': {
            'type': 'FeatureCollection',
            'features': multiMarkers(geoJson)
          }, 
        });

        map.current.addLayer({
          'id': 'multiple-lines-layer',
          'type': 'line',
          'source': 'multiple-lines-source',
          'layout': {
          },
          'paint': {
            'line-color': selectedStyle[0].outline,
            'line-width': 5
          },
        });

        if(showStartStop) {
          startEndMarker(multiMarkers(geoJson)[0], 'first');
          startEndMarker(multiMarkers(geoJson)[0], 'last');
        }

        const coordinates = allCords(geoJson)


        const bounds = new mapboxgl.LngLatBounds(
          coordinates[0],
          coordinates[0]
          );
           
          for (const coord of coordinates) {
            bounds.extend(coord);
          }
           
          map.current.fitBounds(bounds, {
            padding: 20,
            animate: false
          });
      }

      if(stravaJson) {
        // setPoints(multiAltitude(geoJson));

          map.current.addSource('multiple-lines-source', {
            'type': 'geojson',
            'data': {
              'type': 'FeatureCollection',
              'features': multiMarkers(stravaJson, 'strava')
            }, 
          });

          map.current.addLayer({
            'id': 'multiple-lines-layer',
            'type': 'line',
            'source': 'multiple-lines-source',
            'layout': {
            },
            'paint': {
              'line-color': selectedStyle[0].outline,
              'line-width': 5
            },
          });

          if(showStartStop) {
            startEndMarker(stravaJson.coords[0], 'first');
            startEndMarker(stravaJson.coords[0], 'last');
          }

         const coordinates = stravaJson.coords

          const bounds = new mapboxgl.LngLatBounds(
            coordinates[0],
            coordinates[0]
            );
             
            for (const coord of coordinates) {
            bounds.extend(coord);
            }
             
            map.current.fitBounds(bounds, {
              padding: 20
            });
            
        


      }

    }, [geoJson, showStartStop, stravaJson]);




    return (
      <div className={`right-sidebar theme-${style === null ? 'midnight': formatString(style)}`}>
        <div className={`rs-img canvas-size-${canvasSize}`}>
          <div className="branding"><img src={logoStyle(style)} alt="TrailMaps Logo" width={50} height={36} /></div>
          <div ref={mapContainer} className="map-container" />

          <div className={`rs-print ${title && title.length > 19 ? 'medium-title-text' : '' } ${title && title.length > 28 ? 'long-title-text' : ''}`}>
            <div className={`elevation-profile-wrapper ${showElevation !== false ? '' : 'hidden'}`}>
              <Line data={data} options={options}/>
            </div>
            <h2>{elevation === null ? route : elevation}</h2>
            <h1>{title === null ? printTitle : title}</h1>
            <h3>{location === null ? subTitle : location}</h3>

            <div className="rs-print-bottom">
              <h4>{message === null ? printMessage : message}</h4>
              <h4>{small === null ? messageRight: small}</h4>
            </div>
          </div>
        </div>
      </div>
    );
  } 

  const mapStateToProps = (state) => {
    return {
        title: state.title,
        location: state.location,
        message: state.message,
        user: state.user,
        returnTokens: state.returnTokens,
        elevation: state.elevation,
        small: state.small,
        geoJson: state.geoJson,
        style: state.style,
        showElevation: state.showElevation,
        showStartStop: state.showStartStop,
        canvasSize: state.canvasSize,
        stravaJson: state.stravaJson,
        markersJson: state.markersJson,
        zoom: state.zoom
    };
  };

export default connect(mapStateToProps)(Map);
  